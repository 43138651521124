import React from 'react';

import imageEchoDevices from 'src/images/echo_devices.png';

/**
 * Image of two Echo devices
 * @returns 
 */
export default function HeaderImage(): React.ReactElement {
    return (
        <img style={styles.imgEchoDevice} src={imageEchoDevices} />
    );
}

const styles = {
    imgEchoDevice: {
        maxWidth: '100%',
    },
} as const;
