import React from 'react';
import imageCircleSelectionChecked from 'src/images/circle_selection_checked.svg';
import imageCircleSelectionUnchecked from 'src/images/circle_selection_unchecked.svg';

interface CheckBoxProps {
    text: string, checked: boolean, onClick: any
}

export default function CheckBox({ text, checked, onClick }: CheckBoxProps): React.ReactElement {
    const image = checked ? imageCircleSelectionChecked : imageCircleSelectionUnchecked
    return <>
        <div style={styles.checkBoxBorder} />

        <div style={styles.checkBoxContainer} onClick={onClick} className="checkbox">
            <img style={styles.circleSeleciton} src={image} />
            <div style={styles.checkBoxText}>
                {text}
            </div>
        </div>

        <div style={styles.checkBoxBorder} />
    </>
}

const styles = {
    checkBoxContainer: {
        padding: `18px 24px`,
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        cursor: "pointer",
        borderTop: "1px linear-gradient(0deg, rgba(66, 66, 66, 0.1), rgba(66, 66, 66, 0.1))",
    },
    checkBoxBorder: {
        width: "100%",
        height: "1px",
        minHeight: "2px",
        background: "linear-gradient(0deg, rgba(66, 66, 66, 0.1), rgba(66, 66, 66, 0.1))",
    },
    circleSeleciton: {
        width: "20px",
        top: "0px",
    },
    checkBoxText: {
        fontWeight: "bold",
        color: "#424242",
        paddingLeft: "14px",
        fontSize: "16px",
        lineHeight: "20px",
        whiteSpace: "pre-line",
    },
} as const;