import {
    Operation,
    GetCredentialsResult,
    OnContinueMessage,
    RequestNavigateMessage,
    AuthTokenRequestMessage,
    OutboundMessage,
    InboundMessage,
    AuthTokenResponseMessage
} from './schema';
import { IRingApp } from './window.ringapp';

/*
    Ring App API is implemented according to https://confluence.atl.ring.com/pages/viewpage.action?spaceKey=WWA&title=Consent+Flow
    Section "Ring App - WebView communication" and "Ring App-WebView response contract".
*/

export interface RingAppAPIProps {
    ringapp: IRingApp;
    onAuthTokenCallback: (token: string) => void
}

export default class RingAppAPI {
    id: number;
    ringapp: IRingApp;

    constructor({ onAuthTokenCallback, ringapp }: RingAppAPIProps) {
        this.id = 0;
        this.ringapp = ringapp;
        // register the auth token handler.
        this.ringapp.onMessage = (message: string) => {
            const parsedMessage: InboundMessage = JSON.parse(message);
            if (parsedMessage.operation === Operation.GetCredentials) {
                console.log(parsedMessage)
                const authTokenMessage = (parsedMessage as AuthTokenResponseMessage)
                console.log(authTokenMessage)

                // TODO add error handling when token is not fetched.
                if(authTokenMessage.result === GetCredentialsResult.success){
                    onAuthTokenCallback(authTokenMessage.body!.accessToken!);
                }
            }
        }
    }

    /**
    * Inform Ring App that WebView is waiting for MAP token.
    *
    * @param consent
    */
    askForToken(): void {
        this.sendMessage({
            id: ++this.id,
            operation: Operation.GetCredentials
        } as AuthTokenRequestMessage);
    }

    /**
     * Inform Ring App that the "Continue" button has been clicked.
     */
    continueButtonClicked(consent: boolean): void {
        this.sendMessage({
            id: ++this.id,
            operation: Operation.ContinueButtonClicked,
            body: {
                state: {
                    consentAllDevices: consent
                }
            }
        } as OnContinueMessage);
    }

    /**
     * Request Ring App to navigate to this URL
     * @param mesage 
     */
    requestToNavigate(url: string): void {
        this.sendMessage({
            id: ++this.id,
            operation: Operation.Navigate,
            body: {
                target: "browser",
                url,
            }
        } as RequestNavigateMessage);
    }

    private sendMessage(mesage: OutboundMessage): void {
        this.ringapp.sendMessage(JSON.stringify(mesage));
    }
}
