import React, { useEffect, useMemo, useState } from 'react';
import AllOrNothingConsent from 'src/components/pages/consent/AllOrNothingConsent';

import { ConsentFlowAPI, ConsentFlowAPIStub, IConsentFlowAPI, ProviderId } from "src/api/ConsentFlow";
import RingAppAPI from 'src/api/RingApp';
import { mockWindowRingappIfNeeded } from 'src/api/RingApp/window.ringapp';
import { BrowserRouter, Route } from 'react-router-dom';
import { SERVER_URL } from 'src/config';
import i18n, { i18nWhenReady } from 'src/i18n';

mockWindowRingappIfNeeded();

/**
 * Returning real service API or mocked service, depends on the MAP token.
 */
function setupService(token: string): IConsentFlowAPI {
    if (token.toLowerCase().startsWith("Atna|".toLowerCase())) {
        console.log("Received real token, use real service")
        const consentFlowAPI = new ConsentFlowAPI(`${SERVER_URL}/providers/${ProviderId.Ring}`);
        consentFlowAPI.setAuthToken(token);
        return consentFlowAPI;
    } else {
        console.log("Received fake token, use mocked service")
        return new ConsentFlowAPIStub({
            // These value can be manually changed for manual testing
            getStatusSequence: [400, 200], // fail -> ok -> fail -> ok...
            setStatusSequence: [400, 200], // fail -> ok -> fail -> ok...
        });
    }
}

function handleLanguageChange() {
    i18n.changeLanguage();
}

function handlei18nLanguageChanged() {
    // We must manually translate the title of the page.
    const titleEl = document.querySelector<HTMLElement>('#app-title');
    if (titleEl) {
        i18nWhenReady(() => {
            titleEl.innerText = i18n.t('header_text');
        });
    }
}

export default function App(): React.ReactElement {
    const [receivedTokens, setReceivedTokens] = useState(0);
    const [token, setToken] = useState("");

    const consentFlowAPI = useMemo(() => {
        return setupService(token)
    }, [token]);

    const ringAppAPI = useMemo(() => new RingAppAPI({
        onAuthTokenCallback: (token: string) => {
            setToken(token);
            setReceivedTokens(state => state + 1);
        },
        ringapp: window.ringapp!,
    }), []);

    useEffect(() => {
        window.addEventListener('languagechange', handleLanguageChange);
        i18n.on('languageChanged', handlei18nLanguageChanged)

        return () => {
            window.removeEventListener('languagechange', handleLanguageChange);
            i18n.off('languageChanged', handlei18nLanguageChanged);
        };
    }, []);

    return (
        <BrowserRouter>
            <Route path="/">
                <AllOrNothingConsent
                    consentFlowAPI={consentFlowAPI}
                    ringAppAPI={ringAppAPI}
                    receivedTokens={receivedTokens}
                />
            </Route>
        </BrowserRouter>
    );
}
