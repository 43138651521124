export enum ConsentState {
    Granted = "granted",
    NotGranted = "not_granted",
}

export enum ConsentType {
    ALL_OR_NOTHING = "ALL_OR_NOTHING"
}

export enum ProviderId {
    Ring = "Ring"
}

export interface IConsentFlowAPI {
    /**
     * Retrieving current All-Or-Nothing consent state. I.e. has the user granted his consent?
     */
    getConsent: () => Promise<ConsentState>;

    /**
     * Setting the All-Or-Nothing consent state to a desired state. Called when the the user wants to grant/revoke his consent.
     */
    setConsent: (desiredState: ConsentState) => Promise<ConsentState>;
}
