/**
 * Valid Operation values
 */
export enum Operation {
    ContinueButtonClicked = "continue",
    GetCredentials = "get-credentials",
    Navigate = "navigate",
}

/**
 * Get Credentials Operation valid result values
 */
export enum GetCredentialsResult {
    success = "ok",
    failure = "error",
}

/**
 * Messages from Ring App
 */
export interface InboundMessage {
    id: number;
    operation: Operation;
    result?: string;
    error?: string;
    body?: any;
}

export interface AuthTokenResponseMessage extends InboundMessage {
    id: number;
    operation: Operation;
    result: GetCredentialsResult;
    body: {
        accessToken: string,
    };
}

/**
 * Messages to Ring App
 */
export interface OutboundMessage {
    id: number;
    operation: Operation;
}

export interface AuthTokenRequestMessage extends OutboundMessage {
    id: number;
    operation: Operation;
}

export interface OnContinueMessage extends OutboundMessage {
    body: {
        state: {
            consentAllDevices: boolean;
        }
    };
}

export interface RequestNavigateMessage extends OutboundMessage {
    id: number;
    operation: Operation;
    body: {
        target: string,
        url: string,
    };
}
