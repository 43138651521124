import React from 'react';

interface ButtonProps {
    text: string,
    onClick?: React.MouseEventHandler<HTMLDivElement>,
    disabled?: boolean,
    clazzName?: string,
    customStyle?: {
        enabled?: React.CSSProperties,
        disabled?: React.CSSProperties,
    }
}

/**
 * Ring's blue button
 * @returns 
 */
export default function Button({ text, onClick, disabled, clazzName, customStyle }: ButtonProps): React.ReactElement {
    let disabledStyle;
    if (disabled) {
        disabledStyle = {
            ...styles.disabled,
            ...customStyle?.disabled
        }
    } else {
        disabledStyle = {
            ...styles.enabled,
            ...customStyle?.enabled
        }
    }
    return (
        <div
            style={{ ...styles.continueButton, ...disabledStyle }}
            className={clazzName}
            onClick={(e) => {
                if (disabled || !onClick) return;
                onClick!(e);
            }}>
            {text}
        </div>
    );
}

const styles = {
    continueButton: {
        fontSize: "14px",
        lineHeight: "20px",
        color: "#ffffff",
        inset: "0px",
        padding: "12px 16px",
        textAlign: "center",
        borderRadius: "8px",
        letterSpacing: "0.8px",
        fontWeight: "bold",
    },
    enabled: {
        backgroundColor: "#1998D5",
        cursor: "pointer",
    },
    disabled: {
        backgroundColor: "#AFDBF1",
    },
} as const;
