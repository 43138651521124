import "./index"
import { AuthTokenResponseMessage, GetCredentialsResult, Operation, OutboundMessage } from "./schema";

/**
 *  The object `window.ringapp` will be injected by Ring App during website load.
 *  Details are in the App Bridge V2 docs (https://confluence.atl.ring.com/pages/viewpage.action?spaceKey=AD&title=iOS+Webview+common+component).
 */
export interface IRingApp {
    sendMessage: (message: string) => void;
    onMessage: (message: string) => void;
    sendTokenManually?: (message: string) => void;
}
declare global {
    interface Window {
        ringapp?: IRingApp
    }
}

export function createAuthMessageWithToken(token: string): AuthTokenResponseMessage {
    return {
        id: 1,
        result: GetCredentialsResult.success,
        operation: Operation.GetCredentials,
        body: { accessToken: token }
    };
}

export const MOCK_TOKEN_DELAY_MS = 50;

export function mockWindowRingappIfNeeded(): void {
    // If `window.ringapp` object is not found, we need to simulate it ourself.
    window.ringapp = window.ringapp || {
        sendMessage: (message: string) => {
            console.log("Sending message to RingApp (mocked):", message);
            const msg = JSON.parse(message) as OutboundMessage;

            // Mock sending back to WebView MAP token with some delay.
            if (msg.operation === Operation.GetCredentials) {
                setTimeout(() => {
                    window.ringapp!.onMessage(JSON.stringify(createAuthMessageWithToken("fake Map token")));
                }, MOCK_TOKEN_DELAY_MS);
            }
        },
        onMessage: () => { console.log("Mocked on message method"); },

        // This method needs to be called manually to inject valid MAP token.
        // For more information see: "Testing WebView without Ring App integration" section in README file.
        sendTokenManually: (token: string) => {
            console.log("Map token set manually to: ", token);
            window.ringapp!.onMessage(JSON.stringify(createAuthMessageWithToken(token)));
        },
    }
}
