import { IConsentFlowAPI, ConsentState } from './interface';

const LOCAL_STORAGE_KEY = "mock::consent";
const LOCAL_STORAGE_VALUE_GRANTED = "mock::consent_granted";
const LOCAL_STORAGE_VALUE_NOT_GRANTED = "mock::consent_not_granted";

const NETWORK_LANTENCY = 1000; // ms

interface ConsentFlowAPIStubProps {
    getStatusSequence?: number[];
    setStatusSequence?: number[];
    latency?: number;
}

/**
 * A mocking API server simulates the backend service's behavior using browser's localStorage.
 * Gives the ability to control stub server's output. Request can succeed/fail depends on status rotation order.
 */
export class ConsentFlowAPIStub implements IConsentFlowAPI {
    private getStatusSeq?: number[];
    private setStatusSeq?: number[];
    private getIdx = 0;
    private setIdx = 0;
    private latency: number;

    /**
     * Constructor of ConsentFlowAPIStub
     * @param getStatusSequence array of rotating statuses for getConsent.
     *  E.g. [200, 400] --> 1st request ok, 2nd request fails, 1st request ok...
     * @param setStatusSequence array of rotating statuses for setConsent.
     */
    constructor({ getStatusSequence, setStatusSequence, latency }:ConsentFlowAPIStubProps) {
        // By default always success.
        this.getStatusSeq = getStatusSequence || [200];
        this.setStatusSeq = setStatusSequence || [200];
        this.latency = latency || NETWORK_LANTENCY;
    }

    async getConsent(): Promise<ConsentState> {
        await sleep(this.latency);

        const idx = (this.getIdx++)%this.getStatusSeq!.length;
        const status = this.getStatusSeq![idx];
        if (!isStatusOK(status)) {
            throw new Error("Server error " + status);
        }

        const granted = (localStorage.getItem(LOCAL_STORAGE_KEY) === LOCAL_STORAGE_VALUE_GRANTED);
        return granted ? ConsentState.Granted : ConsentState.NotGranted;
    }

    async setConsent(desiredState: ConsentState): Promise<ConsentState> {
        await sleep(this.latency); 


        const idx = (this.setIdx++)%this.setStatusSeq!.length;
        const status = this.setStatusSeq![idx];
        if (!isStatusOK(status)) {
            throw new Error("Server error " + status);
        }

        const granted =  (desiredState === ConsentState.Granted);
        const storedValue = granted ? LOCAL_STORAGE_VALUE_GRANTED : LOCAL_STORAGE_VALUE_NOT_GRANTED;
        localStorage.setItem(LOCAL_STORAGE_KEY, storedValue);
        return granted ? ConsentState.Granted : ConsentState.NotGranted;
    }
}

function isStatusOK(status:number) {
    return 200 <= status && status <= 299;
}

function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}