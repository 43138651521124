import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import ICU from 'i18next-icu';
import LanguageDetector from 'i18next-browser-languagedetector';

import languageMap from '@amzn/katal-localization/webpack-loader!';

type I18nCallback = (err?: string) => void;
type FileSuffix =
    | 'ar-SA'
    | 'de-DE'
    | 'en-AU'
    | 'en-CA'
    | 'en-GB'
    | 'en-IN'
    | 'en-US'
    | 'es-ES'
    | 'es-MX'
    | 'es-US'
    | 'fr-CA'
    | 'fr-FR'
    | 'it-IT'
    | 'ja-JP'
    | 'nl-NL'
    | 'pt-BR';

// Whether initialization is complete
let isInitialized = false;
// If initialization is complete, the last error, if any
let initializationError: string | undefined;

// Registered callbacks for when i18n initialization completes
const i18nInitializationCallbacks: I18nCallback[] = [];

// Map language code to string files in folder `src/i18n/`
// For example, "en" -> "string-en-US.puff.json"
const supportedLocaleToFileSuffix = new Map<string, FileSuffix>([
    // Regional
    ['en-AU', 'en-AU'],
    ['en-CA', 'en-CA'],
    ['en-GB', 'en-GB'],
    ['en-IN', 'en-IN'],
    ['es-MX', 'es-MX'],
    ['es-US', 'es-US'],
    ['fr-CA', 'fr-CA'],

    // Defaults
    ['ar', 'ar-SA'],
    ['de', 'de-DE'],
    ['en', 'en-US'],
    ['es', 'es-ES'],
    ['fr', 'fr-FR'],
    ['it', 'it-IT'],
    ['ja', 'ja-JP'],
    ['nl', 'nl-NL'],
    ['pt', 'pt-BR'],
]);

const defaultLocale: FileSuffix = 'en-US';

i18n.use(Backend)
    .use(ICU)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
        {
            debug: process.env.NODE_ENV !== 'production',
            fallbackLng: defaultLocale,
            interpolation: {
                // not needed for react as it escapes by default
                escapeValue: false
            },
            load: 'currentOnly',
            backend: {
                loadPath: (locales: string[]) => {
                    let preferred: FileSuffix = defaultLocale;
                    locales.some((tag) => {
                        const locale = new Intl.Locale(tag);
                        if (locale.region) {
                            const fileSuffix = supportedLocaleToFileSuffix.get(`${locale.language}-${locale.region}`);

                            if (fileSuffix) {
                                preferred = fileSuffix;
                                return true;
                            }
                        }

                        const fileSuffix = supportedLocaleToFileSuffix.get(locale.language);

                        if (fileSuffix) {
                            preferred = fileSuffix;
                            return true;
                        }

                        return false;
                    });

                    return languageMap[preferred]
                }
            },
            detection: {
                // order and from where user language should be detected
                order: ['navigator']
            }
        },
        (err?: string) => {
            initializationError = err;
            isInitialized = true;
            i18nInitializationCallbacks.forEach(callback => {
                callback(initializationError);
            });
            i18nInitializationCallbacks.length = 0;
        }
    );

/**
 * Helper function to run the given callback to report the status of translation initialization.
 *
 * If translation is already initialized the callback will run immediately, otherwise it will run
 * when translation initialization is complete.
 *
 * This helper function fills a gap in the i18next library, where it doesn't report the initialization
 * status through any of its own events or status flags.
 */
const i18nWhenReady = (callback: I18nCallback): void => {
    if (isInitialized) {
        callback(initializationError);
    } else {
        i18nInitializationCallbacks.push(callback);
    }
};

export { i18nWhenReady };
export default i18n;
