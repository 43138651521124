import {ConsentState, ConsentType, IConsentFlowAPI, ProviderId} from "./interface";

export interface ConsentBody {
    consentType: ConsentType;
    granted: boolean;
    providerId: string;
}

function timeOutAt(duration: number) {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), duration);
    return controller
}

/**
 * This class makes REST calls to the backend service.
 */
export class ConsentFlowAPI implements IConsentFlowAPI {
    static timeout = 10000;

    public serverUrl: string;
    private authToken: string;

    constructor(serverUrl: string) {
        this.serverUrl = serverUrl;
        this.authToken = "";
    }

    setAuthToken(token:string): void {
        this.authToken = token;
    }

    async getConsent(): Promise<ConsentState> {
        const response = await fetch(`${this.serverUrl}/consents`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${this.authToken}`,
            },
            signal: timeOutAt(ConsentFlowAPI.timeout).signal
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }
        const consentValue: ConsentBody = await response.json();
        return (consentValue.granted) ? ConsentState.Granted : ConsentState.NotGranted;
    }

    async setConsent(desiredState: ConsentState): Promise<ConsentState> {
        const body = {
            consentType: ConsentType.ALL_OR_NOTHING,
            granted: (desiredState === ConsentState.Granted),
            providerId: ProviderId.Ring
        } as ConsentBody;

        const response = await fetch(`${this.serverUrl}/consents`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${this.authToken}`,
            },
            body: JSON.stringify(body),
            signal: timeOutAt(ConsentFlowAPI.timeout).signal
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }
        const consentValue: ConsentBody = await response.json();
        return (consentValue.granted) ? ConsentState.Granted : ConsentState.NotGranted;
    }
}
