import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import imageWarning from 'src/images/warning.svg';

interface ErrorBarProps extends WithTranslation {
    onTryAgain?: React.MouseEventHandler<HTMLDivElement>;
}

function ErrorBar({ onTryAgain, t }: ErrorBarProps): React.ReactElement {
    return <>
        <div style={styles.errorBarBorder} />

        <div style={styles.errorBarContainer} className="errorBar">
            <img style={styles.circleSeleciton} src={imageWarning} />
            <div style={styles.errorBarText}>
                {t("error_message_text")}
                <div style={styles.tryAgainText} onClick={onTryAgain} className="try-again">
                    {t("error_retry_text")}
                </div>
            </div>
        </div>

        <div style={styles.errorBarBorder} />
    </>
}
export default withTranslation()(ErrorBar);

const styles = {
    errorBarContainer: {
        padding: `18px 24px`,
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        borderTop: "1px linear-gradient(0deg, rgba(66, 66, 66, 0.1), rgba(66, 66, 66, 0.1))",
    },
    errorBarBorder: {
        width: "100%",
        height: "1px",
        minHeight: "2px",
        background: "linear-gradient(0deg, rgba(66, 66, 66, 0.1), rgba(66, 66, 66, 0.1))",
    },
    circleSeleciton: {
        width: "20px",
        top: "0px",
    },
    errorBarText: {
        fontWeight: "bold",
        color: "#424242",
        paddingLeft: "14px",
        fontSize: "16px",
        lineHeight: "20px",
        whiteSpace: "pre-line",
    },
    tryAgainText: {
        fontSize: "16px",
        lineHeight: "20px",
        color: "#1998D5",
        fontWeight: "bold",
        cursor: "pointer",
    }
} as const;
