import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import HeaderImage from './HeaderImage';
import CheckBox from './CheckBox';
import ErrorBar from './ErrorBar';
import Button from './Button';
import LoadingModal, { LoadingStage } from './LoadingModal';

import { ConsentState, IConsentFlowAPI } from 'src/api/ConsentFlow/interface';
import RingAppAPI from 'src/api/RingApp';
import { HELP_ARTICLE_URL } from 'src/config';

interface AllOrNothingConsentProps extends WithTranslation {
    consentFlowAPI: IConsentFlowAPI;
    ringAppAPI: RingAppAPI;
    receivedTokens: number;
}

function AllOrNothingConsent(props: AllOrNothingConsentProps) {
    const { consentFlowAPI, ringAppAPI, t } = props;

    const [checked, setChecked] = useState(false);
    const [isFailedGet, setIsFailedGet] = useState(false);
    const [isFailedSet, setIsFailedSet] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [modalStage, setModalStage] = useState(LoadingStage.Loading);
    const [requestInProgress, setRequestInProgress] = useState(false);

    // Defines when continue/checkbox buttons should be blocked.
    const shouldDisableActions = () => {
        return !isLoaded || isFailedGet || isFailedSet || requestInProgress || modalStage != LoadingStage.Hidden;
    }

    // Load current consent state
    useEffect(() => {
        let canceled = false;
        const isActive = () => !canceled;

        // If token is already received load customer consent.
        // If not ask the Ring Mobile for the MAP token.
        if (props.receivedTokens > 0) {
            loadView(isActive);
        } else {
            ringAppAPI.askForToken();
        }

        return () => {
            // clean up: cancel the request
            canceled = true
        };
    }, [props.receivedTokens]);


    // Load WebView once the token is retrieved from the Ring App.
    const loadView = (isActive?: () => boolean) => {
        setRequestInProgress(true);
        setIsLoaded(false);
        setModalStage(LoadingStage.Loading);
        consentFlowAPI.getConsent()
            .then((consentState: ConsentState) => {
                if (isActive !== undefined && !isActive()) return;
                console.log("OK")
                console.log(consentState)
                setChecked(consentState === ConsentState.Granted)
                setIsLoaded(true);
                setRequestInProgress(false);
                setIsFailedGet(false);
                setModalStage(LoadingStage.Hidden);
            })
            .catch((error: Error) => {
                if (isActive !== undefined && !isActive()) return;
                console.error(error.message);
                setRequestInProgress(false);
                setIsFailedGet(true);
                setIsLoaded(true);
                console.log("Failed get")
                setModalStage(LoadingStage.Hidden);
            });
    }

    const onClickingHelpArticle = () => {
        ringAppAPI.requestToNavigate(HELP_ARTICLE_URL);
    }

    const onCheckBoxToggle = () => {
        if (requestInProgress) return;
        setChecked(!checked);
    }

    // Grant/revoke consent, and send the current user state
    // when "Continue" button is clicked.
    const onContinue = async () => {
        if (requestInProgress) return;

        setRequestInProgress(true);
        setModalStage(LoadingStage.Updating);

        try {
            await consentFlowAPI.setConsent(checked ? ConsentState.Granted : ConsentState.NotGranted)
            setRequestInProgress(false);
            setIsFailedSet(false);
            ringAppAPI.continueButtonClicked(checked);
            setModalStage(LoadingStage.Hidden)
        } catch (error: any) {
            console.error(error.message);
            setRequestInProgress(false);
            setIsFailedSet(true);
            console.log("Failed put!")
            setModalStage(LoadingStage.Failed);
        }
    }

    return (
        <div style={styles.container}>

            <HeaderImage />

            <div style={styles.textContainer}>
                <div style={styles.header}>{t("header_text")}</div>
                <div style={styles.paragraph}>
                    {t("p1_text")}
                    <br />
                    <br />
                    {t("p2_text")}
                </div>
                <div style={styles.help} onClick={onClickingHelpArticle} className="help-article">
                    {t("view_help_text")}
                </div>
            </div>
            {
                (!isFailedGet && isLoaded) && <CheckBox text={t("check_box_text")} checked={checked} onClick={onCheckBoxToggle} />
            }
            {
                isFailedGet && !requestInProgress && <ErrorBar onTryAgain={() => loadView()} />
            }
            <div style={styles.continueButtonContainer}>
                <Button
                    text={t("continue_button_text")}
                    onClick={onContinue}
                    disabled={shouldDisableActions()}
                    clazzName="continue-button"
                />
            </div>
            <LoadingModal
                stage={modalStage}
                handlers={{
                    failed: {
                        onTryAgain: onContinue,
                        onCancel: () => setModalStage(LoadingStage.Hidden)
                    }
                }}
            />
        </div>
    );
}
export default withTranslation()(AllOrNothingConsent);

const styles = {
    container: {
        position: "relative",
        maxWidth: "375px",
        margin: "auto",
        backgroundColor: "white",
    },

    // Main text styles
    textContainer: {
        padding: `24px 24px`,
    },
    header: {
        fontSize: "20px",
        lineHeight: "24px",
        textAlign: "left",
        color: "#424242",
        margin: `8px 0px`,
        fontWeight: "bold",
    },
    paragraph: {
        fontSize: "16px",
        lineHeight: "20px",
        textAlign: "left",
        marginTop: "16px",
        marginBottom: "24px",
        color: "#424242",
    },
    help: {
        fontSize: "16px",
        lineHeight: "20px",
        color: "#1998D5",
        fontWeight: "bold",
        cursor: "pointer",
    },
    error: {
        fontSize: "16px",
        lineHeight: "20px",
        color: "#ff0000",
        fontWeight: "bold",
        marginTop: "8px",
    },
    continueButtonContainer: {
        overflow: "auto",
        bottom: "0px",
        padding: "24px",
        maxWidth: "100%",
        width: (375 - 24 * 2) + "px",
        backgroundColor: "#ffffff",
    }
} as const;
