import React from 'react';
import imageUpdate from 'src/images/update.svg';
import imageCross from 'src/images/cross.svg';
import { TFunction, withTranslation, WithTranslation } from 'react-i18next';
import Button from './Button';

export enum LoadingStage {
    Hidden = "hidden",
    Loading = "loading",
    Updating = "updating",
    Failed = "failed",
}

interface LoadingModalProps extends WithTranslation {
    stage: LoadingStage;
    handlers: {
        failed: {
            onTryAgain?: React.MouseEventHandler<HTMLDivElement>;
            onCancel?: React.MouseEventHandler<HTMLDivElement>;
        }
    }
}

function LoadingModal({ stage, handlers, t }: LoadingModalProps): React.ReactElement {
    let content = null;
    switch (stage) {
        case LoadingStage.Loading:
            content = <Loading text={t("loading")} />
            break;
        case LoadingStage.Updating:
            content = <Loading text={t("updating")} />
            break;
        case LoadingStage.Failed:
            content = <Failure
                t={t}
                onTryAgain={handlers.failed.onTryAgain}
                onCancel={handlers.failed.onCancel}
            />
            break;
        case LoadingStage.Hidden:
        default:
            return <></>;
    }
    return <div style={styles.overlay} onClick={e => e.stopPropagation()}>
        {content}
    </div>
}
export default withTranslation()(LoadingModal);

interface LoadingProps {
    text: string;
}
function Loading({ text }: LoadingProps) {
    return <div style={styles.content} className="loading">
        <img style={{
            ...styles.icon,
            animation: `spin 1s linear infinite`,
        }} src={imageUpdate} />
        <div style={styles.loadingText}>
            {text}
        </div>
    </div>
}

interface FailureProps {
    t: TFunction<string>;
    onTryAgain?: React.MouseEventHandler<HTMLDivElement>;
    onCancel?: React.MouseEventHandler<HTMLDivElement>;
}
function Failure({ onTryAgain, onCancel, t }: FailureProps) {
    return <div style={{ ...styles.content, ...styles.failureContent }}>
        <img style={{ ...styles.icon, marginTop: "51.11px" }} src={imageCross} />
        <div style={styles.failureText}>
            <b>{t("failed_to_update")}</b>
            <br />
            {t("please_try_again")}
        </div>
        <div style={styles.tryAgainButtonContainer}>
            <Button
                onClick={onTryAgain}
                text={t("try_again_button_text")}
                clazzName="try-again-button"
            />
        </div>
        <div style={styles.cancelButtonContainer}>
            <Button

                onClick={onCancel}
                text={t("cancel_button_text")}
                customStyle={styles.cancelButtonStyles}
                clazzName="try-again-button"
            />
        </div>
    </div>
}

const styles = {
    overlay: {
        position: 'fixed',
        inset: "0px",
        backgroundColor: "rgba(0, 0, 0, 0.65)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    content: {
        width: "280px",
        height: "188px",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        border: "1px solid rgba(255, 255, 255, 1)",
        boxShadow: "0px 8px 12px 0px rgba(0, 0, 0, 0.15)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    },
    icon: {
        height: "53.33px",
        width: "53.33px",
        top: "45.33px",
    },
    loadingText: {
        marginTop: "29.33px",
        fontSize: "16px",
        lineHeight: "20px",
        letterSpacing: "0px",
        textAlign: "center",
        fontWeight: "bold",
    },
    failureContent: {
        height: "340px",
        width: "327px",
    },
    failureText: {
        marginTop: "43.33px",
        marginBottom: "16px",
    },
    tryAgainButtonContainer: {
        width: (327 - 16 * 2) + "px",
        margin: "16px",
        marginBottom: "12px",
    },
    cancelButtonContainer: {
        width: (327 - 16 * 2) + "px",
        margin: "16px",
        marginTop: "0px",
    },
    cancelButtonStyles: {
        enabled: {
            backgroundColor: "#ffffff",
            color: "#1998D5",
        }
    }
} as const;
